
import Axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import { CompanyInfo } from "../../../models/super-admin/company-details.model";
import Notification from "../../../common-components/Notification.vue"

@Component({
  components:{
Notification
  }
})
export default class DashBoard extends Vue {
  public allCommunicationAndNotification: any = [];
  public companyInfo: any = new CompanyInfo();
  public message = ''

  public async showAllNotificationAndCommunication() {
    try {
      const response = await Axios.get(
        `${BASE_API_URL}super-admin/dashboard/getAllNotificationAndCommunication`,
        { headers: authHeader() }
      );
      if (response) {
        this.allCommunicationAndNotification = response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  /* eslint-disable */
  public loanCount: any = [];
  public brokerCount: any = [];
  public processorCount: any = [];
  public agentCount: any = [];
  public lenderCount: any = [];

  public async getLoanCount() {
    try {
      const response = await Axios.get(
        BASE_API_URL + "super-admin/dashboard/getLoanCount",
        {
          headers: authHeader(),
        }
      );
      this.loanCount = response.data.allLoans;
      this.processorCount = response.data.allProcessor;
      this.brokerCount = response.data.allBroker;
      this.agentCount = response.data.allAgent;
      this.lenderCount = response.data.alllender
      
    } catch (error) {
      console.log(error);
    }
  }

  public async getCompanyDetail() {
    try {
      const response = await Axios.get(
        `${BASE_API_URL}super-admin/company-details/getCompanyDetails`,
        {
          headers: authHeader(),
        }
      );
      if (response.data.length > 0) this.companyInfo = response.data[0];
      // console.log(this.companyInfo, "jubair");
    } catch (error) {
      console.log(error);
    }
  }


  public async ViewNotificationModal(message) {
    try{
          this.message = message.message;
            this.$bvModal.show("ViewNotificationModal");
    }  catch (error) {
      console.log(error);
    }
  }


  async mounted() {
    await this.getLoanCount();
    this.showAllNotificationAndCommunication();
    await this.getCompanyDetail();
  }
  /* eslint-disable */
}
